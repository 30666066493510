<template lang="pug">
FormKit(type='form' :actions='false' @submit='sendPaylink')
  template(#default='{ state, value }')
    BaseFormRadio(
      name='type'
      label='Delivery Method'
      :options='[ { label: "Email", value: "EMAIL" }, { label: "Phone", value: "PHONE" }, ]'
      validation='required'
      fieldset-class='!p-0'
    )

    BaseFormInputEmail(
      v-if='value?.type === "EMAIL"'
      label='Customer Email'
      name='email'
      validation='required'
      :validation-messages='{ required: "Please enter a valid email" }'
    )
    FormKit(
      v-else-if='value?.type === "PHONE"'
      id='paylinkPhone'
      type='tel'
      validation='required'
      label='Customer Phone'
      name='phone'
      maxlength='22'
      autocomplete='tel'
    )

    div(class='flex justify-end gap-x-2')
      BaseButton(color='WHITE' size='SMALL' type='button' :is-disabled='state.loading' @click='closeForm') Cancel
      BaseButton(color='ACTION' size='SMALL' type='submit' :is-disabled='!state.valid' :is-waiting='state.loading') Send
</template>

<script setup lang="ts">
const customerManager = useCustomerManager()
const notifications = useNotifications()

const emit = defineEmits(['close'])

const isSending = ref(false)

async function sendPaylink(data) {
  // Double click protection and make sure we have a customer before we try to send the paylink
  if (isSending.value || !customerManager.customer.value) return
  isSending.value = true

  try {
    await customerManager.sendPaylinkToCustomer(customerManager.customer.value.id, {
      email: data.email,
      phone: data.phone,
    })

    notifications.addSuccess('Paylink Success!', `Paylink has been sent to ${data.email || data.phone}`)

    closeForm()
  } catch (error) {
    notifications.addError('Unable to send paylink to customer', error?.data?.msg)
  } finally {
    isSending.value = false
  }
}

function closeForm() {
  emit('close')
}
</script>
